<template>
  <v-card class="d-flex flex-column">
    <v-card-title> Events </v-card-title>

    <v-card-text>
      <v-text-field
        autocomplete="off"
        v-model="searchTerm"
        :label="$t('chirpStackHub.searchTitle')"
      />

      <v-chip-group
        class="float-left"
        v-model="types"
        multiple
        active-class="accent"
      >
        <v-chip v-for="type in selectionTypes" :key="type" :value="type">
          {{ $t("chirpStackHub.tabHeader." + type) }}
        </v-chip>
      </v-chip-group>

      <v-btn
        :disabled="!pause"
        class="float-right"
        text
        color="primary"
        @click="handleResume"
      >
        {{ $t("chirpStackHub.resumeEventFlow") }}
      </v-btn>
    </v-card-text>
    <v-card-text style="height: 100%; max-height: 100%; overflow-y: scroll">
      <v-expansion-panels multiple hover accordion v-model="openPanels">
        <v-expansion-panel v-for="ev in getEvents" :key="ev.id">
          <v-expansion-panel-header
            :class="`py-0 event-header-${ev.eventType}-${ev.eventTypeName}`"
            hide-actions
          >
            <v-col cols="12" md="12" sm="12">
              <v-tooltip top v-for="(head, index) in ev.header" :key="index">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-if="head.startsWith('Data')"
                    class="ma-1"
                    :color="darkmode ? 'black' : 'white'"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{
                      head.length > elipsisLength
                        ? head.substring(0, elipsisLength) + "&hellip;"
                        : head
                    }}
                  </v-chip>

                  <v-chip v-else-if="index == 0" class="ma-1" color="primary">
                    <v-icon class="mr-2" color="secondary" left>
                      {{ getIconName(ev.eventType) }}
                    </v-icon>
                    {{ head }}
                  </v-chip>

                  <v-chip
                    v-else-if="index == ev.header.length - 1"
                    class="ma-1 float-right"
                    :color="darkmode ? 'black' : 'white'"
                  >
                    {{ head }}
                  </v-chip>

                  <v-chip
                    v-else
                    class="ma-1"
                    :color="darkmode ? 'black' : 'white'"
                  >
                    {{ head }}
                  </v-chip>
                </template>
                {{ head }}
              </v-tooltip>
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0 ma-0">
            <json-card v-model="ev.content" copyButton />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
import { ChirpStackMessageType, EventTypes } from "../../_helpers/CsharpEnum";
import JsonCard from "../common/JsonCard.vue";

const SelectionTypes = {
  Data: 10,
  Attribute: 20,
  Device: 2,
  Gateway: 0,
};

const ElipsisLength = 40;
const MaxNumberOfEvents = 100;
const MaxQueueSize = 100;

export default {
  name: "LiveHub",

  data() {
    return {
      hub: null,
      types: [
        SelectionTypes.Data,
        SelectionTypes.Attribute,
        SelectionTypes.Device,
      ],
      events: [],
      displayGatewayTypeSelector: false,
      searchTerm: "",

      pauseQue: [],
      openPanels: [],

      isProcessing: false,
      eventQueue: [],
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL", "darkmode"]),
    ...mapGetters("users", { hubToken: "token" }),

    selectionTypes() {
      if (this.displayGatewayTypeSelector) return SelectionTypes;
      let temp = { ...SelectionTypes };
      delete temp.Gateway;
      return temp;
    },

    pause() {
      return this.openPanels.length > 0;
    },

    elipsisLength: () => ElipsisLength,

    getEvents() {
      if (this.searchTerm.length == 0)
        return this.events
          .filter((x) => this.types.includes(x.eventType))
          .reverse();

      return this.events
        .filter(
          (x) =>
            this.types.includes(x.eventType) &&
            x.header.filter((y) =>
              y
                .toLocaleLowerCase()
                .includes(this.searchTerm.toLocaleLowerCase())
            ).length > 0
        )
        .reverse();
    },
  },

  methods: {
    _enqueueEvent(event) {
      if (document.visibilityState !== "visible") return;

      if (this.pause) {
        this.pauseQue.push(event);
        if (this.pauseQue.length > MaxQueueSize) this.pauseQue.shift();

        this.isProcessing = false;
        return;
      }

      this.eventQueue.push(event);
      this.scheduleProcessing();
    },

    scheduleProcessing() {
      // Check if processing is currently scheduled
      if (!this.isProcessing) {
        this.isProcessing = true;
        requestAnimationFrame(this.processQueue);
      }
    },

    processQueue() {
      // Process the first 10 events in the queue
      const batch = this.eventQueue.splice(0, 10);
      let result = [];
      for (const payload of batch) {
        var event = null;
        switch (payload.type) {
          case EventTypes.CHIRPSTACK:
            event = this.handleChirpStackInput(payload);
            break;
          case EventTypes.DATA:
            event = this.handleDataInput(payload);
            break;
          case EventTypes.ATTRIBUTE:
            event = this.handleAttributeInput(payload);
            break;
        }
        if (event) {
          result.push(event);
        }
      }

      if (result.length > 0) {
        // We explicitly say to vue to update dom with new results
        this.$set(this, "events", [...this.events, ...result]);

        if (this.events.length > MaxNumberOfEvents)
          this.events.splice(0, this.events.length - MaxNumberOfEvents);
      }

      this.isProcessing = false;
      if (this.eventQueue.length > 0) {
        this.scheduleProcessing();
      }
    },

    handleChirpStackInput(payload) {
      let json = JSON.parse(payload.message);

      let messageType =
        json.MessageType == ChirpStackMessageType.Device_V3 ||
        json.MessageType == ChirpStackMessageType.Device_V4
          ? SelectionTypes.Device
          : SelectionTypes.Gateway;

      let typeName = json.Type;

      delete json.MessageType;
      delete json.Topic;
      delete json.Type;

      let header = [];
      if (messageType == SelectionTypes.Device) {
        header.push(`Device - ${typeName}`);
        header.push(`${json.DeviceInfo?.DeviceName ?? json.Devicename}`);
        header.push(`${payload.tagName}`);
        if (json.Data) header.push(`Data: ${json.Data}`);
      } else {
        header.push(`Gateway - ${typeName}`);
        header.push(`${payload.tagName}`);
        this.displayGatewayTypeSelector = true;
      }
      header.push(`${this.humanDate(payload.date, "HH:mm:ss")}`);

      return {
        id: payload.id,
        companyId: payload.companyId,
        date: payload.date,
        topic: payload.topic,
        header: header,
        content: json,
        eventTypeName: typeName,
        eventType: messageType,
      };
    },

    handleDataInput(payload) {
      let header = [];
      header.push(`${this.$t("eventhub.eventType." + payload.type)}`);
      header.push(`${payload.tagName}`);
      header.push(`${payload.deveui}`);
      if (payload.data?.data)
        header.push(
          `Data ${this.$t("chirpStackHub.updatedValue")} ${Object.keys(
            payload.data.data
          )}`
        );

      header.push(`${this.humanDate(payload.date, "HH:mm:ss")}`);

      let content = payload.data;
      if (!content.meta) delete content.meta;
      if (!content.createdAt) content.createdAt = payload.date;
      if (content.meta?.jsonGateways) delete content.meta.jsonGateways;

      return {
        id: payload.id,
        companyId: payload.companyId,
        date: payload.date,
        topic: payload.topic,
        header: header,
        content: content,
        eventTypeName: "new-data",
        eventType: SelectionTypes.Data,
      };
    },

    handleAttributeInput(payload) {
      let header = [];
      header.push(`${this.$t("eventhub.eventType." + payload.type)}`);
      header.push(`${payload.tagName}`);
      header.push(`${payload.deveui}`);
      if (payload.data?.data)
        header.push(
          `Data ${this.$t("chirpStackHub.updatedValue")} ${Object.keys(
            payload.data.data
          )}`
        );

      header.push(`${this.humanDate(payload.date, "HH:mm:ss")}`);

      let content = payload.data;
      delete content.meta;

      return {
        id: payload.id,
        companyId: payload.companyId,
        date: payload.date,
        topic: payload.topic,
        header: header,
        content: content,
        eventTypeName: "attribute-change",
        eventType: SelectionTypes.Attribute,
      };
    },

    handleResume() {
      this.openPanels = [];
    },

    getIconName(eventType) {
      switch (eventType) {
        case SelectionTypes.Data:
          return "mdi-monitor-arrow-down-variant";
        case SelectionTypes.Attribute:
          return "mdi-autorenew";
        case SelectionTypes.Device:
          return "mdi-connection";
        case SelectionTypes.Gateway:
          return "mdi-access-point";
      }
    },
  },

  components: {
    JsonCard,
  },

  async created() {
    if (this.hubToken === undefined || this.SO_API_BASE_URL === undefined) {
      console.error("Could not find token for user to use for LiveHub");
      return;
    }
    this.hub = new HubConnectionBuilder()
      .withUrl(this.SO_API_BASE_URL + "/events", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => this.hubToken,
      })
      .configureLogging(LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    this.hub.on("BroadcastEvent", this._enqueueEvent);
    this.hub.start();
  },

  unmounted() {
    this.handleResume();
  },

  async beforeDestroy() {
    if (!this.hub) return;
    this.hub.off("BroadcastEvent", this._enqueueEvent);
    this.hub.stop();
    this.hub = null;
  },

  watch: {
    pause: {
      handler(val) {
        if (!val) {
          this.pauseQue.forEach((x) => {
            this._enqueueEvent(x);
          });
          this.pauseQue = [];
        }
      },
    },
    types: {
      handler() {
        this.handleResume();
      },
    },
  },
};
</script>
<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0px;
}

//ChirpStack Device up
.event-header-2-up {
  //background: #3afd5b50;
  background: #3d96fd50;
}

//ChirpStack Device join
.event-header-2-join {
  background: #3d96fd50;
}

//ChirpStack Device error
.event-header-2-error {
  background: #fc1e1e50;
}

//ChirpStack Gateway up
.event-header-0-up {
  //background: #3afd5b50;
  background: #3d96fd50;
}

//ChirpStack Gateway state
.event-header-0-stats {
  background: #c7c7c750;
  //background: #3d96fd50;
}

//ChirpStack Gateway ack
.event-header-0-ack {
  //background: #3d96fd50;
  background: #3d96fd50;
}

//New Data
.event-header-10-new-data {
  background: #3afd5b50;
}

//Attribute change
.event-header-20-attribute-change {
  background: #3afd5b50;
}
</style>
