<template>
  <div :class="depthClass">
    <div
      v-for="(key, i) in getKeys"
      :key="'jsonrow: ' + i"
      :class="isObject(key) ? '' : 'd-flex align-center justify-start'"
      :style="isObject(key) ? '' : 'max-width: 100%; overflow-x: auto'"
    >
      <div class="overline primary--text pr-1">
        <v-icon @click="expand(key)" v-if="isObject(key)">{{
          isExpanded[key] ? "mdi-menu-up" : "mdi-menu-down"
        }}</v-icon>
        <v-icon v-else style="margin-left: 24px"></v-icon>
        {{ key + ": " }}
      </div>
      <div v-if="!isObject(key)">
        <pre v-html="syntaxHighlight(value[key])" />
      </div>
      <json-row
        v-if="isObject(key) && isExpanded[key]"
        v-model="value[key]"
        :depth="depth + 1"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "JsonRow",

  props: {
    value: {
      default: () => {},
    },

    depth: {
      type: Number,
      default: 0,
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {
      isExpanded: {},
    };
  },

  computed: {
    depthClass() {
      return `pl-${this.depth * 3}`;
    },

    getKeys() {
      return this.value ? Object.keys(this.value) : [];
    },
  },
  methods: {
    isObject(key) {
      return (
        this.value &&
        this.value[key] &&
        typeof this.value[key] === "object" &&
        (Object.keys(this.value[key]).length > 0 || this.value[key].length > 0)
      );
    },

    expand(key) {
      this.isExpanded[key] = !this.isExpanded[key];
    },

    syntaxHighlight(value) {
      var cls = "string";
      if (/^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
        cls = "number";
      } else if (/true|false/.test(value)) {
        cls = "boolean";
      } else if (/null/.test(value)) {
        cls = "null";
      }

      if (
        value &&
        Object.keys(value).length === 0 &&
        value.constructor === Object
      ) {
        value = "";
      }

      return '<span class="' + cls + '">' + value + "</span>";
    },
  },

  async created() {
    var expandedObj = {};
    if (this.value) {
      for (var k of Object.keys(this.value)) {
        expandedObj[k] = true;
      }
      this.isExpanded = expandedObj;
    }
  },
};
</script>
