<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-text class="bary-items">
      <div
        v-for="(category, index) in categories"
        :key="index"
        :class="`bary-item`"
      >
        <h4>
          {{ category.title }}
          {{ includeValue ? `${category.value}% ` : "" }}
        </h4>
        <h5 class="subtitle">{{ `${category.count} / ${category.total}` }}</h5>
        <div
          :class="`elevation-4 bary-item-bar ${category.color}`"
          :style="`width: ${category.value}% !important`"
        >
          <!-- <h4>{{ category.count }}</h4> -->
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Bary",

  props: {
    title: {
      type: String,
      required: true,
    },

    categories: {
      type: Array,
      required: true,
    },

    includeValue: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    for (var category of this.categories) {
      if (!category.title)
        console.error("Bary.vue Categories must have a title.");

      if (category.value === undefined) {
        console.error(
          "Bary.vue Categories must have a value as the precentile amount out of 100."
        );
        continue;
      }

      if (category.value > 100)
        console.error(
          "Bary.vue Categories must have a value less than or equal to 100."
        );
    }
  },
};
</script>

<style scoped>
.bary-container {
  background: var(--v-card-background-color);
  padding: 1rem;
  border-radius: 10px;
  drop-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.bary-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  padding-left: 0;
}

.bary-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.bary-item h4 {
  padding-left: 1rem;
  line-height: 1;
}

.bary-item h5 {
  padding-top: 0;
  padding-bottom: 0.5rem;
  margin-top: 0;
  padding-left: 1rem;
  line-height: 1;
}

.bary-item-bar {
  height: 20px;
  border-radius: 0 4px 4px 0;
}
</style>
