<template>
  <v-main
    align="center"
    style="max-height: 100%; height: 100%; position: absolute; width: 100%"
  >
    <v-container
      style="width: 100%; max-height: 100%; height: 100%"
      fluid
      class="pa-0"
    >
      <v-row style="height: 100%">
        <v-col
          cols="12"
          md="5"
          sm="12"
          xs="12"
          style="height: 100%"
          class="pr-0 d-flex flex-column"
        >
          <bary
            class="mt-1 mx-4"
            title="Nod Status"
            :categories="[
              {
                title: $t('common.statusActive'),
                value: activeTagPrecentile,
                color: 'green',
                count: activeTagCount,
                total: activeTagCount + inactiveTagCount,
              },
              {
                title: $t('common.statusInactive'),
                value: inactiveTagPrecentile,
                color: 'red',
                count: inactiveTagCount,
                total: activeTagCount + inactiveTagCount,
              },
            ]"
          />

          <bary
            class="mt-4 mx-4"
            title="Gateway Status"
            :categories="[
              {
                title: $t('common.statusActive'),
                value: gatewayOnlinePrecentile,
                color: 'green',
                count: activeGatewayCount,
                total: gateways.length,
              },
              {
                title: $t('common.statusInactive'),
                value: gatewayOfflinePrecentile,
                color: 'red',
                count: inactiveGatewaysCount,
                total: gateways.length,
              },
            ]"
          />

          <v-card class="mt-4 mx-4" style="flex: 1; overflow: scroll">
            <v-card-title>{{ $t("alarm.tabs.triggered") }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                style="height: 100% !important"
                :items="triggeredAlarms"
                :headers="triggeredAlarmHeaders"
              >
                <template v-slot:[`item.statusColor`]="{ item }">
                  <v-avatar
                    class="rounded-box"
                    :color="getStatusColor(item).color"
                    size="30"
                  >
                    <v-icon
                      :class="{
                        'status-icon': true,
                        blinking: getStatusColor(item).blinking,
                      }"
                      :style="{
                        backgroundColor: getStatusColor(item).color,
                        fontSize: '20px',
                      }"
                    >
                      {{ getStatusColor(item).icon }}
                    </v-icon>
                  </v-avatar>
                </template>

                <!-- Latest Reset Time -->
                <template v-slot:[`item.lastAcknowledged`]="{ item }">
                  {{ getLatestResetTime(item.alarmId) }}
                </template>

                <!-- ? Auto Acknowledge -->
                <template v-slot:[`item.autoAcknowledge`]="{ item }">
                  <v-icon
                    :color="item.autoAcknowledge ? 'success' : 'error'"
                    size="30"
                    >{{
                      item.autoAcknowledge
                        ? "mdi mdi-check-circle"
                        : "mdi mdi-alpha-x-circle"
                    }}</v-icon
                  >
                </template>

                <template v-slot:[`item.activeAlarms.length`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-badge
                          :color="
                            item.activeAlarms.length > 0 ? 'error' : 'success'
                          "
                          overlap
                          bordered
                        >
                          <template v-slot:badge>
                            {{ item.activeAlarms.length }}
                          </template>
                          <v-avatar
                            :color="
                              item.activeAlarms.length > 0 ? 'error' : 'success'
                            "
                            size="25"
                          >
                            <v-icon
                              @click="$router.push(`/alarm`)"
                              size="15"
                              color="white"
                            >
                              mdi-alarm-light
                            </v-icon>
                          </v-avatar>
                        </v-badge>
                      </div>
                    </template>
                    <span>{{ $t("alarm.acknowledgeButton") }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="7"
          sm="12"
          xs="12"
          style="height: 100%"
          class="pl-0 pt-0"
        >
          <live-hub class="mt-4 mx-2" style="height: 100%" />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex/dist/vuex.common.js";
import Bary from "../components/common/Bary.vue";
import LiveHub from "../components/events/LiveHub.vue";
import TagRepository from "@/api/repositories/tagRepository";

export default {
  name: "LandingLoggedIn",

  data() {
    return {
      gateways: [],

      activeTagCount: 0,
      inactiveTagCount: 0,
      activeTagPrecentile: 0,
      inactiveTagPrecentile: 0,

      activeGatewayCount: 0,
      inactiveGatewaysCount: 0,
      gatewayOnlinePrecentile: 0,
      gatewayOfflinePrecentile: 0,

      triggeredAlarmHeaders: [
        { text: "Status", value: "statusColor" },
        { text: this.$t("alarm.fields.name"), value: "name" },
        { text: this.$t("alarm.fields.reset"), value: "lastAcknowledged" },
        {
          text: this.$t("alarm.fields.autoAcknowledge"),
          value: "autoAcknowledge",
        },
        {
          text: this.$t("alarm.fields.activeAlarms"),
          value: "activeAlarms.length",
        },
      ],
    };
  },

  components: {
    LiveHub,
    Bary,
  },

  computed: {
    ...mapGetters("alarms", ["triggeredAlarms"]),
    ...mapState("alarms", ["activeAlarms"]),
  },

  methods: {
    ...mapActions("gateways", ["getGateways"]),
    ...mapActions("alarms", ["getAlarms", "getAlarmHistory"]),

    async countInactiveGateways() {
      let count = 0;
      for (let i = 0; i < this.gateways.length; i++) {
        if (
          !this.gateways[i].lastSeen ||
          !this.timeWithinHours(this.gateways[i].lastSeen, 1)
        )
          count++;
      }
      return count;
    },

    getStatusColor(item) {
      let icon,
        color,
        blinking = false;

      if (!item.active) {
        icon = "mdi-alarm-note-off";
        color = "lightgrey";
      } else if (item.activeAlarms.length > 0) {
        icon = "mdi-alert-outline";
        color = "error";
        blinking = true;
      } else if (item.flagging) {
        icon = "mdi-alert-outline";
        color = "warning";
      } else {
        icon = "mdi-check-circle-outline";
        color = "success";
      }

      return { icon, color, blinking };
    },

    getLatestResetTime(alarmId) {
      const relevantActivesForAlarm = this.activeAlarms.filter(
        (active) => active.alarm.alarmId === alarmId
      );
      if (relevantActivesForAlarm.length > 0) {
        const latestActive = relevantActivesForAlarm.reduce((latest, current) =>
          new Date(current.updatedAt) > new Date(latest.updatedAt)
            ? current
            : latest
        );
        return this.humanDate(latestActive.updatedAt, "dd MMM - HH:mm:ss");
      }
      return "N/A";
    },
  },

  async created() {
    var tagResult = (await TagRepository.getActiveInactiveCount())?.data;
    var totalTags = tagResult?.active + tagResult?.inactive;
    this.activeTagCount = tagResult?.active ?? 0;
    this.inactiveTagCount = tagResult?.inactive ?? 0;
    this.activeTagPrecentile = parseFloat(
      ((this.activeTagCount / totalTags) * 100).toFixed(2)
    );
    this.inactiveTagPrecentile = parseFloat(
      ((this.inactiveTagCount / totalTags) * 100).toFixed(2)
    );

    this.gateways = await this.getGateways();
    this.inactiveGatewaysCount = await this.countInactiveGateways();
    this.activeGatewayCount = this.gateways.length - this.inactiveGatewaysCount;
    this.gatewayOnlinePrecentile = parseFloat(
      (
        ((this.gateways.length - this.inactiveGatewaysCount) /
          this.gateways.length) *
        100
      ).toFixed(2)
    );
    this.gatewayOfflinePrecentile = parseFloat(
      ((this.inactiveGatewaysCount / this.gateways.length) * 100).toFixed(2)
    );

    await this.getAlarms();
    await this.getAlarmHistory();
  },
};
</script>
