<template>
  <div>
    <v-btn v-if="copyButton" @click="handleCopy" class="float-right ma-4">
      {{ $t("common.copy") }}
    </v-btn>
    <div class="text-left pb-4">
      <json-row v-model="json" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import JsonRow from "./JsonRow.vue";

export default {
  name: "JsonCard",

  props: {
    value: {
      default: null,
    },

    copyButton: {
      default: false,
      type: Boolean,
    },
  },

  model: {
    prop: "value",
  },

  components: {
    JsonRow,
  },

  computed: {
    json() {
      if (this.value && typeof this.value == "object") return this.value;
      return this.value ? JSON.parse(this.value) : {};
    },

    jsonString() {
      if (this.value && typeof this.value == "string") return this.value;
      return this.value ? JSON.stringify(this.value) : "";
    },
  },

  methods: {
    ...mapActions("alert", ["success", "error"]),

    async handleCopy() {
      let success = true;
      try {
        await navigator.clipboard.writeText(this.jsonString).then(
          function () {
            success = true;
          },
          function (e) {
            success = false;
            console.error("Could not copy to clipboard", e);
          }
        );
      } catch (e) {
        console.error("Could not copy to clipboard", e);
        success = false;
      }

      if (success) this.success(this.$t("eventhub.copySuccess"));
      else this.error(this.$t("eventhub.copyError"));
    },
  },
};
</script>
