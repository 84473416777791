var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticStyle:{"max-height":"100%","height":"100%","position":"absolute","width":"100%"},attrs:{"align":"center"}},[_c('v-container',{staticClass:"pa-0",staticStyle:{"width":"100%","max-height":"100%","height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"pr-0 d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"cols":"12","md":"5","sm":"12","xs":"12"}},[_c('bary',{staticClass:"mt-1 mx-4",attrs:{"title":"Nod Status","categories":[
            {
              title: _vm.$t('common.statusActive'),
              value: _vm.activeTagPrecentile,
              color: 'green',
              count: _vm.activeTagCount,
              total: _vm.activeTagCount + _vm.inactiveTagCount,
            },
            {
              title: _vm.$t('common.statusInactive'),
              value: _vm.inactiveTagPrecentile,
              color: 'red',
              count: _vm.inactiveTagCount,
              total: _vm.activeTagCount + _vm.inactiveTagCount,
            },
          ]}}),_c('bary',{staticClass:"mt-4 mx-4",attrs:{"title":"Gateway Status","categories":[
            {
              title: _vm.$t('common.statusActive'),
              value: _vm.gatewayOnlinePrecentile,
              color: 'green',
              count: _vm.activeGatewayCount,
              total: _vm.gateways.length,
            },
            {
              title: _vm.$t('common.statusInactive'),
              value: _vm.gatewayOfflinePrecentile,
              color: 'red',
              count: _vm.inactiveGatewaysCount,
              total: _vm.gateways.length,
            },
          ]}}),_c('v-card',{staticClass:"mt-4 mx-4",staticStyle:{"flex":"1","overflow":"scroll"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("alarm.tabs.triggered")))]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticStyle:{"height":"100% !important"},attrs:{"items":_vm.triggeredAlarms,"headers":_vm.triggeredAlarmHeaders},scopedSlots:_vm._u([{key:`item.statusColor`,fn:function({ item }){return [_c('v-avatar',{staticClass:"rounded-box",attrs:{"color":_vm.getStatusColor(item).color,"size":"30"}},[_c('v-icon',{class:{
                      'status-icon': true,
                      blinking: _vm.getStatusColor(item).blinking,
                    },style:({
                      backgroundColor: _vm.getStatusColor(item).color,
                      fontSize: '20px',
                    })},[_vm._v(" "+_vm._s(_vm.getStatusColor(item).icon)+" ")])],1)]}},{key:`item.lastAcknowledged`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getLatestResetTime(item.alarmId))+" ")]}},{key:`item.autoAcknowledge`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":item.autoAcknowledge ? 'success' : 'error',"size":"30"}},[_vm._v(_vm._s(item.autoAcknowledge ? "mdi mdi-check-circle" : "mdi mdi-alpha-x-circle"))])]}},{key:`item.activeAlarms.length`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-badge',{attrs:{"color":item.activeAlarms.length > 0 ? 'error' : 'success',"overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(item.activeAlarms.length)+" ")]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"color":item.activeAlarms.length > 0 ? 'error' : 'success',"size":"25"}},[_c('v-icon',{attrs:{"size":"15","color":"white"},on:{"click":function($event){return _vm.$router.push(`/alarm`)}}},[_vm._v(" mdi-alarm-light ")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("alarm.acknowledgeButton")))])])]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"pl-0 pt-0",staticStyle:{"height":"100%"},attrs:{"cols":"12","md":"7","sm":"12","xs":"12"}},[_c('live-hub',{staticClass:"mt-4 mx-2",staticStyle:{"height":"100%"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }